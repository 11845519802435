export const USER_TYPE_ANONYMOUS = "ANONYMOUS";
export const USER_TYPE_PCAADMIN = "PCA_ADMIN";
export const USER_TYPE_PROJECTMANAGER = "PROJECT_MANAGER";
export const USER_TYPE_ORGANIZATIONMANAGER = "ORGANIZATION_MANAGER";
export const USER_TYPE_MERCHANT = "MERCHANT";
export const USER_TYPE_MARKETGROUPMANAGER = "MARKET_GROUP_MANAGER";

export const WITHOUT_SUBSCRIPTION = "withoutSubscription";

export const CARD_STATUS_ASSIGNED = "ASSIGNED";
export const CARD_STATUS_UNASSIGNED = "UNASSIGNED";
export const CARD_STATUS_LOST = "LOST";
export const CARD_STATUS_GIFT = "GIFT_CARD";

export const CHECK_CARD_STEPS_START = "START";
export const CHECK_CARD_STEPS_SCAN = "SCAN";
export const CHECK_CARD_STEPS_COMPLETE = "COMPLETE";

export const SORT_DEFAULT = "DEFAULT";
export const SORT_RANDOM = "RANDOM";

export const TRANSACTION_STEPS_START = "START";
export const TRANSACTION_STEPS_SCAN = "SCAN";
export const TRANSACTION_STEPS_ADD = "ADD";
export const TRANSACTION_STEPS_COMPLETE = "COMPLETE";
export const TRANSACTION_STEPS_MANUALLY_ENTER_CARD_NUMBER = "MANUALLY_ENTER_CARD_NUMBER";
export const TRANSACTION_FINISH = "FINISH";

export const PRODUCT_GROUP_LOYALTY = "PCA::Loyalty";

export const BENEFICIARY_STATUS_INACTIVE = "INACTIVE";
export const BENEFICIARY_STATUS_ACTIVE = "ACTIVE";

export const BENEFICIARY_WITH_CARD = "WITH_CARD";
export const BENEFICIARY_WITHOUT_CARD = "WITHOUT_CARD";

export const BENEFICIARY_WITH_PAYMENT_CONFLICT = "WITH_PAYMENT_CONFLICT";
export const BENEFICIARY_WITHOUT_PAYMENT_CONFLICT = "WITHOUT_PAYMENT_CONFLICT";

export const CARD_IS_DISABLED = "CARD_IS_DISABLED";
export const CARD_IS_ENABLED = "CARD_IS_ENABLED";

export const EXPIRE_FUND_TRANSACTION_LOG = "EXPIRE_FUND_TRANSACTION_LOG";
export const LOYALTY_ADDING_FUND_TRANSACTION_LOG = "LOYALTY_ADDING_FUND_TRANSACTION_LOG";
export const MANUALLY_ADDING_FUND_TRANSACTION_LOG = "MANUALLY_ADDING_FUND_TRANSACTION_LOG";
export const OFF_PLATFORM_ADDING_FUND_TRANSACTION_LOG = "OFF_PLATFORM_ADDING_FUND_TRANSACTION_LOG";
export const PAYMENT_TRANSACTION_LOG = "PAYMENT_TRANSACTION_LOG";
export const REFUND_BUDGET_ALLOWANCE_FROM_NO_CARD_WHEN_ADDING_FUND_TRANSACTION_LOG =
  "REFUND_BUDGET_ALLOWANCE_FROM_NO_CARD_WHEN_ADDING_FUND_TRANSACTION_LOG";
export const REFUND_BUDGET_ALLOWANCE_FROM_REMOVED_BENEFICIARY_FROM_SUBSCRIPTION_TRANSACTION_LOG =
  "REFUND_BUDGET_ALLOWANCE_FROM_REMOVED_BENEFICIARY_FROM_SUBSCRIPTION_TRANSACTION_LOG";
export const REFUND_BUDGET_ALLOWANCE_FROM_UNASSIGNED_CARD_TRANSACTION_LOG =
  "REFUND_BUDGET_ALLOWANCE_FROM_UNASSIGNED_CARD_TRANSACTION_LOG";
export const REFUND_PAYMENT_TRANSACTION_LOG = "REFUND_PAYMENT_TRANSACTION_LOG";
export const SUBSCRIPTION_ADDING_FUND_TRANSACTION_LOG = "SUBSCRIPTION_ADDING_FUND_TRANSACTION_LOG";
export const TRANSFER_FUND_TRANSACTION_LOG = "TRANSFER_FUND_TRANSACTION_LOG";

export const LANGUAGE_FILTER_EN = "ENGLISH";
export const LANGUAGE_FILTER_FR = "FRENCH";

export const ADDING_FUND_TRANSACTION_STATUS_ACTIVED = "ACTIVED";
export const ADDING_FUND_TRANSACTION_STATUS_EXPIRED = "EXPIRED";
export const ADDING_FUND_TRANSACTION_STATUS_UNASSIGNED = "UNASSIGNED";

export const USER_STATUS_DISABLED = "DISABLED";
export const USER_STATUS_ACTIVED = "ACTIVED";
