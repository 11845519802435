export const CLAIM_UTYPE = "Sig.App:UserType";
export const CLAIM_UTYPE_PCAADMIN = "PCAAdmin";
export const CLAIM_UTYPE_PROJECTMANAGER = "ProjectManager";
export const CLAIM_UTYPE_ORGANIZATIONMANAGER = "OrganizationManager";
export const CLAIM_UTYPE_MERCHANT = "Merchant";
export const CLAIM_UTYPE_MARKETGROUPMANAGER = "MarketGroupManager";
export const CLAIM_UTYPE_PROJECTMANAGER_OF = "Sig.App:ProjectManagerOf";
export const CLAIM_UTYPE_MARKETMANAGER_OF = "Sig.App:MarketManagerOf";
export const CLAIM_UTYPE_ORGANIZATIONMANAGER_OF = "Sig.App.OrganizationManagerOf";
export const CLAIM_UTYPE_MARKETGROUPMANAGER_OF = "Sig.App.MarketGroupManagerOf";
