export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "menu-title-program": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " program"])},
        "program-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program settings"])},
        "manage-organization-managers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User management"])},
        "manage-project-managers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User management"])},
        "manage-marketgroup-managers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User management"])},
        "manage-project-export-all-participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export all participants"])},
        "reconciliation-report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reconciliation report"])},
        "cash-register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash registers"])},
        "menu-title-market": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " market"])},
        "menu-title-market-group": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " market group"])}
      },
      "fr": {
        "menu-title-program": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Programme ", _interpolate(_named("name"))])},
        "program-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres du programme"])},
        "manage-organization-managers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des utilisateurs"])},
        "manage-project-managers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des utilisateurs"])},
        "manage-marketgroup-managers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des utilisateurs"])},
        "manage-project-export-all-participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter tous les participants"])},
        "reconciliation-report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport de réconciliation"])},
        "cash-register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caisses"])},
        "menu-title-market": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Marché ", _interpolate(_named("name"))])},
        "menu-title-market-group": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Groupe de commerce ", _interpolate(_named("name"))])}
      }
    }
  })
}
